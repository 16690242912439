<template>
    <painel titulo="Origem de contratação" :refreshFunction="obterTodos" v-if="mostrarListagem">
        <div class="table-responsive my-5">
            <listagem-sol
                :idTabela="idTabela"
                :campos="campos"
                :data="data"
                :inativos="inativos"
                @onInativar="inativar"
                @onEditar="editar"
                @onAtivar="ativar"
                @onInserir="inserir"
                @mostrarInativos="mostrarInativos"
            ></listagem-sol>
        </div>
    </painel>
    <router-view></router-view>
</template>

<script>
import ListagemSol from '../components/ListagemSol.vue';
import Services from './service';

export default {
    name: 'Origem de contratação',
    components: {
        ListagemSol,
    },
    data() {
        return {
            idTabela: 'Origem-contratação',
            inativos: true,
            data: [],
            campos: [
                { campo: 'title', titulo: 'Título' },
                { campo: 'valor', titulo: 'Valor' },
                { campo: 'limiteArea', titulo: 'Limite de área' },
                { campoModified: 'modified', titulo: 'Modificado' },
                { campo: 'modifiedBy', titulo: 'Modificado por' },
            ],
        };
    },
    mounted() {
        this.obterTodos();
    },
    computed: {
        mostrarListagem() {
            return this.$route.name === 'OrigemContratacao';
        },
    },
    beforeRouteUpdate() {
        this.obterTodos();
    },
    methods: {
        obterTodos() {
            this.$store.dispatch('addRequest');
            Services.obterTodos(this.inativos ? null : true).then((response) => {
                if (response?.success) {
                    this.data = response.data;
                    this.data.forEach((politica) => {
                        politica.valor = new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(politica.valor);
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
        mostrarInativos(inativo) {
            this.inativos = inativo;
        },
        inativar(objeto) {
            this.$store.dispatch('addRequest');
            Services.excluir(objeto.id).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Inativação',
                        detail: 'Origem de contratação inativada com sucesso',
                        life: 3000,
                    });
                    this.obterTodos();
                }
                this.$store.dispatch('removeRequest');
            });
        },
        ativar(objeto) {
            this.$store.dispatch('addRequest');
            Services.atualizar(objeto.id, objeto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ativação',
                        detail: 'Origem de contratação ativada com sucesso',
                        life: 3000,
                    });
                    this.obterTodos();
                }
                this.$store.dispatch('removeRequest');
            });
        },
        inserir() {
            this.$router.push({
                name: `Origem_Contratacao_Inserir`,
            });
        },
        editar(origemContratacao) {
            this.$router.push({
                name: `Origem_Contratacao_Atualizar`,
                params: {
                    id: origemContratacao.id,
                },
            });
        },
    },
    watch: {
        inativos() {
            this.obterTodos();
        },
    },
};
</script>
